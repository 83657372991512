import HomePage from "./Pages/HomePage/HomePage";
import NavBar from "./utils/NavBar/NavBar";
import ContactPage from "./Pages/ContactPage/ContactPage";
import BlogPage from "./Pages/BlogPage/BlogPage";
import ResumePage from "./Pages/ResumePage/ResumePage";
import SinglePage from "./Pages/BlogPage/SinglePost";
import React, { useState, useEffect } from "react";
import sanityClient from "./client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./utils/Footer/Footer";
import NotFoundPage from "./Pages/NotFoundPage/NotFoundPage";
import CheatSheet from './Pages/CheatSheetPage/CheatSheet';
function App() {
  const [postData, setPost] = useState();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
      title,
      slug,
      mainImage{
        asset->{
          _id,
          url
        },alt
      },
      body, publishedAt,
      "name":author->name,
      "authorImage":author->image
    }`
      )
      .then((data) => setPost(data))
      .catch(console.error);
  }, []);

  // console.log(postData);

  return (
    <Router>
      <NavBar></NavBar>
      <Routes>
        <Route
          exact
          path="/"
          element={<HomePage latestPost={postData}></HomePage>}
        ></Route>
        <Route
          path="/blog"
          element={<BlogPage postData={postData}></BlogPage>}
        ></Route>
        <Route
          exact
          path="/blog/:slug"
          element={<SinglePage></SinglePage>}
        ></Route>
        <Route
          exact
          path="/profile"
          element={<ResumePage></ResumePage>}
        ></Route>
        <Route
          exact
          path="/contact"
          element={<ContactPage></ContactPage>}
        ></Route>
        <Route
          exact
          path="/orm-cheatsheet"
          element={<CheatSheet></CheatSheet>}
        ></Route>
        <Route path="*" element={<NotFoundPage></NotFoundPage>}></Route>
      </Routes>
      <Footer></Footer>
    </Router>
  );
}

export default App;
