import React from "react";
import { Heading, Center, Container, Image, Text } from "@chakra-ui/react";
import lost from "./lost-johntravolta.gif";
const NotFoundPage = () => {
  return (
    <Container>
      <Center>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <Heading>Page Not Found!</Heading>
      </Center>

      <Center>
        <Text>This is not that place bro!</Text>
        <br></br>
        <br></br>
      </Center>
      <br></br>
      <br></br>
      <Image src={lost}></Image>
    </Container>
  );
};

export default NotFoundPage;
