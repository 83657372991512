import React from "react";

import {
  chakra,
  Box,
  Flex,
  useColorModeValue,
  Icon,
  SimpleGrid,
  Stack,
  GridItem,
  Center,
  Heading,
  Text,
} from "@chakra-ui/react";
export default function Wfl() {
  const Feature = (props) => {
    return (
      <Flex>
        <Flex shrink={0}>
          <Icon
            boxSize={5}
            mt={1}
            mr={2}
            color={useColorModeValue("brand.500", "brand.300")}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </Icon>
        </Flex>
        <Box ml={4}>
          <chakra.dt
            fontSize="lg"
            fontWeight="bold"
            lineHeight="6"
            color={useColorModeValue("gray.900")}
          >
            {props.title}
          </chakra.dt>
          <chakra.dd mt={2} color={useColorModeValue("gray.500", "gray.400")}>
            {props.children}
          </chakra.dd>
        </Box>
      </Flex>
    );
  };
  return (
    <>
      <Flex
        bg={useColorModeValue("#F9FAFB", "gray.600")}
        //   p={20}
        w="auto"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          // shadow="xl"
          bg={useColorModeValue("white", "gray.800")}
          px={8}
          py={20}
          mx="auto"
        >
          <Center mb={20}>
            <br></br>
            <Heading color={useColorModeValue("teal.600", "teal.300")}>
              My Experience
            </Heading>
          </Center>

          <SimpleGrid
            alignItems="center"
            columns={{ base: 1, lg: 3 }}
            spacingY={{ base: 10, lg: 32 }}
            spacingX={{ base: 10, lg: 24 }}
          >
            <Box alignSelf="start">
              <chakra.h2
                color={useColorModeValue("brand.500")}
                fontWeight="semibold"
                textTransform="uppercase"
                letterSpacing="wide"
              >
                May 2021 to CURRENT
              </chakra.h2>
              <chakra.h2
                mb={3}
                fontSize={{ base: "3xl", md: "4xl" }}
                fontWeight="extrabold"
                textAlign={{ base: "center", sm: "left" }}
                color={useColorModeValue("black")}
                lineHeight="shorter"
                letterSpacing="tight"
              >
                Fractal Analytics
              </chakra.h2>
              <Text>📍 Bengaluru, KA, IND</Text>
              <chakra.p
                mb={6}
                fontSize={{ base: "lg", md: "xl" }}
                textAlign={{ base: "center", sm: "left" }}
                color={useColorModeValue("gray.600", "gray.500")}
              >
                Currently Working as Cloud and Data Tech Engineer.
              </chakra.p>
            </Box>
            <GridItem colSpan={2}>
              <Stack
                spacing={{ base: 10, md: 0 }}
                display={{ md: "grid" }}
                gridTemplateColumns={{ md: "repeat(2,1fr)" }}
                gridColumnGap={{ md: 8 }}
                gridRowGap={{ md: 10 }}
              >
                <Feature title="Working as Azure DevOps Engineer">
                  Working and Debugging on MS Azure Web App Cloud Service,
                  assigning VPN, Configurations to deploy a Fully Scaled and
                  Stacked Web App{" "}
                </Feature>
                <Feature title="Debugged React Front End API calls">
                  Debugged Existing Fullstack Application, added featured and
                  integrated new services to the Web App
                </Feature>
                <Feature title="Debugged Django Code and Fixed issues in Django">
                  Debugged Django Applications and checked for Migration issues
                  with data and knows to implement ORM in Django{" "}
                </Feature>
                <Feature title="Collaborated on Email Component in Django">
                  {" "}
                  Worked on adding Email component in Django Web App to notify
                  users on Application Updates{" "}
                </Feature>
              </Stack>
            </GridItem>
          </SimpleGrid>
          <br></br>
          <br></br>
          <br></br>

          <SimpleGrid
            alignItems="center"
            columns={{ base: 1, lg: 3 }}
            spacingY={{ base: 10, lg: 32 }}
            spacingX={{ base: 10, lg: 24 }}
          >
            <Box alignSelf="start">
              <chakra.h2
                color={useColorModeValue("brand.500")}
                fontWeight="semibold"
                textTransform="uppercase"
                letterSpacing="wide"
              >
                March 2021 to May 2022
              </chakra.h2>
              <chakra.h2
                mb={3}
                fontSize={{ base: "3xl", md: "4xl" }}
                fontWeight="extrabold"
                textAlign={{ base: "center", sm: "left" }}
                color={useColorModeValue("black")}
                lineHeight="shorter"
                letterSpacing="tight"
              >
                Infosys PVT LTD
              </chakra.h2>
              <Text>📍 Bengaluru, KA, IND</Text>
              <chakra.p
                mb={6}
                fontSize={{ base: "lg", md: "xl" }}
                textAlign={{ base: "center", sm: "left" }}
                color={useColorModeValue("gray.600", "gray.500")}
              >
                Worked as Technology Analyst.
              </chakra.p>
            </Box>
            <GridItem colSpan={2}>
              <Stack
                spacing={{ base: 10, md: 0 }}
                display={{ md: "grid" }}
                gridTemplateColumns={{ md: "repeat(2,1fr)" }}
                gridColumnGap={{ md: 8 }}
                gridRowGap={{ md: 10 }}
              >
                <Feature title="Working as AWS Developer">
                  Built Lambda Functions and ran deployment scripts for better
                  deployment of cloud functions. Used dashboard to find
                  different elements of metrics for cloud functions{" "}
                </Feature>
                <Feature title="Built Scripts with Serverless for deployment">
                  Worked in building serverless scripts for automated deployment
                  of AWS Lambdas
                </Feature>
                <Feature title="Worked in building REDIS cache for Enterprise software">
                  {" "}
                  Built code for processing and adding incremental Redis cache
                  in the cloud for faster performance of code
                </Feature>
                <Feature title="Developed Core modules in Python">
                  {" "}
                  Worked on building an enterprise price calculation machine for
                  getting real-time price data from Machine Learning{" "}
                </Feature>
                <Feature title="Built API documentation frontend with react">
                  {" "}
                  Developed frontend for API documentation for AWS API gateway
                  using Swagger.io and React JS.{" "}
                </Feature>
              </Stack>
            </GridItem>
          </SimpleGrid>
          <br></br>
          <br></br>
          <br></br>
          <SimpleGrid
            alignItems="center"
            columns={{ base: 1, lg: 3 }}
            spacingY={{ base: 10, lg: 32 }}
            spacingX={{ base: 10, lg: 24 }}
          >
            <Box alignSelf="start">
              <chakra.h2
                color={useColorModeValue("brand.500")}
                fontWeight="semibold"
                textTransform="uppercase"
                letterSpacing="wide"
              >
                Feb 2017 2021 to March 2021
              </chakra.h2>
              <chakra.h2
                mb={3}
                fontSize={{ base: "3xl", md: "4xl" }}
                fontWeight="extrabold"
                textAlign={{ base: "center", sm: "left" }}
                color={useColorModeValue("black")}
                lineHeight="shorter"
                letterSpacing="tight"
              >
                Cognizant Tech Solutions
              </chakra.h2>
              <Text>📍 Coimbatore, TN, IND</Text>
              <chakra.p
                mb={6}
                fontSize={{ base: "lg", md: "xl" }}
                textAlign={{ base: "center", sm: "left" }}
                color={useColorModeValue("gray.600", "gray.500")}
              >
                Worked as Programmer Analyst
              </chakra.p>
            </Box>
            <GridItem colSpan={2}>
              <Stack
                spacing={{ base: 10, md: 0 }}
                display={{ md: "grid" }}
                gridTemplateColumns={{ md: "repeat(2,1fr)" }}
                gridColumnGap={{ md: 8 }}
                gridRowGap={{ md: 10 }}
              >
                <Feature title="Worked as Test Engineer">
                  Worked on testing the American Healthcare Claims validator
                  building VBA scripts and python scripts to project them{" "}
                </Feature>
                <Feature title="Developed presentations as Business Analyst">
                  Presented forecasted test data and behaviour healthcare claims
                  validations of internally build tools by health care product
                  developer Trizetto
                </Feature>
                <Feature title="Validated Claims Via Microsoft BizTalk 360 server">
                  {" "}
                  Worked on MS BizTalk 360 server for validating EDI 837 claims.
                  Knows to run scripts to multiple validations of files
                </Feature>
                <Feature title="Built Automation scripts with Python">
                  {" "}
                  Using Pandas built test automation script to consume 837 EDI
                  claims and bills{" "}
                </Feature>
              </Stack>
            </GridItem>
          </SimpleGrid>
        </Box>
      </Flex>
    </>
  );
}
