import React from "react";
import {
  chakra,
  Box,
  Flex,
  useColorModeValue,
  Button,
  Heading,
  Center,
  useColorMode,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const BlogPage = ({ postData }) => {
  const grey_600 = useColorModeValue("#F9FAFB", "gray.600");
  const grey_800 = useColorModeValue("white", "gray.800");
  const { colorMode } = useColorMode();
  return (
    <>
      <Box bg={useColorModeValue("#F9FAFB", "gray.600")}>
        <br></br>
        <br></br>
        <Center>
          <Heading>My Posts!</Heading>
        </Center>
        <br></br>
      </Box>

      {typeof postData === "undefined" ? (
        <Center>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <Spinner></Spinner>
        </Center>
      ) : (
        postData &&
        postData.map((post, index) => (
          <Flex
            bg={grey_600}
            p={50}
            w="full"
            alignItems="center"
            justifyContent="center"
            key={index}
          >
            <Box
              bg={grey_800}
              mx={{ lg: 8 }}
              display={{ lg: "flex" }}
              maxW={{ lg: "5xl" }}
              shadow={{ lg: "lg" }}
              rounded={{ lg: "lg" }}
            >
              <Box w={{ lg: "50%" }}>
                <Box
                  h={{ base: 64, lg: "full" }}
                  rounded={{ lg: "lg" }}
                  bgSize="cover"
                  style={{
                    backgroundImage: `url(${post.mainImage.asset.url})`,
                  }}
                ></Box>
              </Box>

              <Box
                py={12}
                px={6}
                maxW={{ base: "xl", lg: "5xl" }}
                w={{ lg: "50%" }}
              >
                <chakra.h2
                  fontSize={{ base: "2xl", md: "3xl" }}
                  color={colorMode === "light" ? "gray.600" : "#F9FAFB"}
                  fontWeight="bold"
                >
                  {post.title}
                </chakra.h2>
                <chakra.p
                  mt={4}
                  color={colorMode === "light" ? "gray.600" : "#F9FAFB"}
                >
                  <Flex>
                    <Text noOfLines={5}>
                      {post.body[0]["children"][0]["text"]}
                    </Text>
                  </Flex>
                </chakra.p>

                <Box mt={8}>
                  <Link
                    to={"/blog/" + post.slug.current}
                    key={post.slug.current}
                  >
                    <Button
                      bg="gray.900"
                      color="gray.100"
                      _hover={{ bg: "gray.800" }}
                      variant="ghost"
                    >
                      Read More
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Box>
          </Flex>
        ))
      )}
    </>
  );
};

export default BlogPage;
