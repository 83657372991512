import React from "react";
import {
  Box,
  Flex,
  useColorModeValue,
  Heading,
  Center,
  Container,
  Image,
  Circle,
} from "@chakra-ui/react";
import TitleComponent from "./TitleComponent";
import SkillComponent from "./SkillComponent";
import ExperienceComponent from "./ExperienceComponent";
import ProjectComponent from "./ProjectComponent";
import MyHobbies from "./MyHobbies";
import avatar from "./images/profile-image.png";

//Work in Progress

const ResumePage = () => {
  return (
    <>
      <Box
        // w="md"
        mx="auto"
        py={50}
        px={8}
        h={400}
        backgroundImage="url(https://images.unsplash.com/photo-1521185496955-15097b20c5fe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80)"
        backgroundPosition="center"
      >
        <Flex justifyContent={{ base: "center", md: "center" }} mt={60}>
          <Center>
            <Circle size="200px">
              <Image src={avatar} borderRadius="100" border="2px"></Image>
            </Circle>
          </Center>
        </Flex>
        <br></br>
        <br></br>
      </Box>

      <br></br>
      <br></br>

      <Container maxW="container.xl">
        <br></br>
        <br></br>

        <Center>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <Heading color={useColorModeValue("teal.600", "teal.300")}>
            Hi, I am Siva, Developer / AI Engineer
          </Heading>
        </Center>
        <Box>
          <TitleComponent></TitleComponent>
          <SkillComponent></SkillComponent>

          <ExperienceComponent></ExperienceComponent>
          <ProjectComponent></ProjectComponent>
          <MyHobbies></MyHobbies>
        </Box>
      </Container>
    </>
  );
};

export default ResumePage;
