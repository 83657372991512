import React from "react";
import {
  useColorMode,
  Box,
  Container,
  Spacer,
  HStack,
  IconButton,
  Image,
  Heading,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useColorModeValue,
} from "@chakra-ui/react";
import logo from "../../static/logos/final_logo.svg";
import { Link } from "react-router-dom";
import { SunIcon, MoonIcon, HamburgerIcon } from "@chakra-ui/icons";
const NavBar = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <header>
      <Container maxW="container.xl">
        <Box>
          <HStack>
            {/* Logo */}
            <Box size="sm">
              <Link to="/">
                <Image
                  src={logo}
                  boxSize="50px"
                  objectFit="cover"
                  alt="shivthedev"
                />
              </Link>
            </Box>
            <Heading color={useColorModeValue("teal.600", "teal.300")}>
              ShivtheDev.Tech
            </Heading>
            {/* Menu Buttons */}
            <Spacer></Spacer>
            <Flex display={["none", "none", "flex", "flex"]}>
              <Link to="/blog">
                <Button colorScheme="teal" variant="ghost">
                  Blog
                </Button>
              </Link>
              <Link to="/profile">
                <Button colorScheme="teal" variant="ghost">
                  My Profile
                </Button>
              </Link>
              <Link to="/contact">
                <Button colorScheme="teal" variant="ghost">
                  Contact Me
                </Button>
              </Link>

              <Link to="/orm-cheatsheet">
                <Button colorScheme="teal" variant="ghost">
                  ORM Cheat Sheet
                </Button>
              </Link>
            </Flex>
            {/* Burger Menu Starts here */}
            <Flex display={["flex", "flex", "none", "none"]}>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<HamburgerIcon />}
                  variant="outline"
                />
                <MenuList>
                  <Link to="/">
                    <MenuItem>Home</MenuItem>
                  </Link>
                  <Link to="/blog">
                    <MenuItem>Blog</MenuItem>
                  </Link>
                  <Link to="/profile">
                    <MenuItem>portfolio</MenuItem>
                  </Link>

                  <Link to="/contact">
                    <MenuItem>Contact Me</MenuItem>
                  </Link>

                  <Link to="/orm-cheatsheet">
                    <MenuItem>Orm Cheat Sheet</MenuItem>
                  </Link>
                </MenuList>
              </Menu>
            </Flex>
            {/* Sun Moon Icon */}
            <Box p="2">
              <IconButton
                aria-label="LightMode"
                icon={
                  colorMode === "light" ? <SunIcon /> : <MoonIcon></MoonIcon>
                }
                onClick={() => toggleColorMode()}
              ></IconButton>
            </Box>
          </HStack>
        </Box>
      </Container>
    </header>
  );
};

export default NavBar;
