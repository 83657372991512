import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import BlockContent from "@sanity/block-content-to-react";
import {
  Container,
  Heading,
  Center,
  Spinner,
  Image,
  Text,
  chakra,
  Flex,
  Spacer,
  Link,
  useColorMode,
} from "@chakra-ui/react";
import sanityClient from "../../client";
import { v4 as uuid } from "uuid";

const SinglePost = () => {
  const [singlePost, setSinglePost] = useState(null);
  const { slug } = useParams();
  const { colorMode } = useColorMode();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${slug}"]{
      title,
      _id,
      slug,
      mainImage{
        asset->{
          _id,
          url
        }
      },
      body,publishedAt,
      "name":author -> name,
      "authorImage": author->image
    }`
      )
      .then((data) => setSinglePost(data[0]))
      .catch(console.error);
  }, [slug]);

  // console.log(singlePost);

  if (!singlePost)
    return (
      <Center>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <Spinner></Spinner>
      </Center>
    );

  return (
    <>
      <Image
        w="full"
        h={400}
        fit="cover"
        src={singlePost.mainImage.asset.url}
        alt={singlePost.name}
      ></Image>
      <Container maxW="container.xl">
        <br></br>
        <br></br>

        <Center>
          <br></br>
          <br></br>
          <Heading>{singlePost.title}</Heading>
          <br></br>
          <br></br>
        </Center>
        <br></br>
        <br></br>

        <Flex alignItems="center">
          <Image
            h={10}
            fit="cover"
            rounded="full"
            src={`https://cdn.sanity.io/images/n81y97ra/production/${singlePost.authorImage.asset._ref.substring(
              6,
              singlePost.authorImage.asset._ref.length - 4
            )}.png`}
            alt="Avatar"
          />
          <Link
            mx={2}
            fontWeight="bold"
            fontSize="25px"
            color={colorMode === "light" ? "gray.700" : "gray.200"}
            key={uuid()}
          >
            {singlePost.name}
          </Link>

          <Spacer />
          <chakra.span
            mx={1}
            fontWeight="bold"
            fontSize="15px"
            color={colorMode === "light" ? "gray.600" : "gray.300"}
            key={uuid()}
          >
            Published At :{" "}
            {new Date(singlePost.publishedAt).toLocaleDateString()}
          </chakra.span>
        </Flex>
        <br></br>
        <br></br>
        <Center>
          <Text>
            <BlockContent
              blocks={singlePost.body}
              projectId="n81y97ra"
              dataset="production"
            ></BlockContent>
          </Text>
        </Center>
      </Container>
    </>
  );
};

export default SinglePost;
