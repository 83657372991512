import React from "react";
import {
  Container,
  Heading,
  Center,
  Spinner,
  chakra,
  Box,
  Image,
  Flex,
  Link,
  useColorMode,
  Text,
} from "@chakra-ui/react";
import { v4 as uuid } from "uuid";
import shivthedev_gif_banner from "../../static/shivthedev_banner.png";
import { Link as routerLink } from "react-router-dom";
const HomePage = ({ latestPost }) => {
  const { colorMode } = useColorMode();

  return typeof latestPost === "undefined" ? (
    <Center>
      <Spinner></Spinner>
    </Center>
  ) : (
    <Container maxW="container.xl">
      <br></br>
      <br></br>
      <br></br>
      <Center>
        <Image objectFit="cover" src={shivthedev_gif_banner} />
      </Center>
      <br></br>
      <Center>
        <Heading>Latest Posts</Heading>
      </Center>
      <br></br>
      <br></br>
      <br></br>
      {typeof latestPost === "undefined" ? (
        <Center>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <Spinner></Spinner>
        </Center>
      ) : (
        latestPost &&
        latestPost.map((post, index) => (
          <div key={index}>
            <Flex
              bg={colorMode === "light" ? "#F9FAFB" : "gray.600"}
              p={50}
              w="full"
              alignItems="center"
              justifyContent="center"
              key={uuid()}
            >
              <Box
                mx="auto"
                rounded="lg"
                shadow="md"
                bg={colorMode === "light" ? "white" : "gray.800"}
                maxW="2xl"
                key={uuid()}
              >
                <Image
                  roundedTop="lg"
                  key={uuid()}
                  w="full"
                  h={64}
                  fit="cover"
                  src={`${post.mainImage.asset.url}`}
                  alt="Article"
                />
                <Box p={6}>
                  <Box>
                    <chakra.span
                      fontSize="xs"
                      textTransform="uppercase"
                      color={colorMode === "light" ? "brand.600" : "brand.400"}
                    >
                      Product
                    </chakra.span>
                    <Link
                      as={routerLink}
                      to={"/blog/" + post.slug.current}
                      display="block"
                      color={colorMode === "light" ? "gray.800" : "white"}
                      fontWeight="bold"
                      fontSize="2xl"
                      mt={2}
                      _hover={{ color: "gray.600", textDecor: "underline" }}
                    >
                      {post.title}
                    </Link>
                    <chakra.p
                      mt={2}
                      fontSize="sm"
                      color={colorMode === "light" ? "gray.600" : "gray.400"}
                      key={uuid()}
                    >
                      <Flex>
                        <Text noOfLines={3}>
                          {post.body[0]["children"][0]["text"]}
                        </Text>
                      </Flex>
                    </chakra.p>
                  </Box>

                  <Box mt={4}>
                    <Flex alignItems="center">
                      <Flex alignItems="center">
                        <Image
                          h={10}
                          fit="cover"
                          rounded="full"
                          src={`https://cdn.sanity.io/images/n81y97ra/production/${post.authorImage.asset._ref.substring(
                            6,
                            post.authorImage.asset._ref.length - 4
                          )}.png`}
                          alt="Avatar"
                        />
                        <Link
                          mx={2}
                          fontWeight="bold"
                          color={
                            colorMode === "light" ? "gray.700" : "gray.200"
                          }
                          key={uuid()}
                        >
                          {post.name}
                        </Link>
                      </Flex>
                      <chakra.span
                        mx={1}
                        fontSize="sm"
                        color={colorMode === "light" ? "gray.600" : "gray.300"}
                        key={uuid()}
                      >
                        {new Date(post.publishedAt).toLocaleDateString()}
                      </chakra.span>
                    </Flex>
                  </Box>
                </Box>
              </Box>
            </Flex>
          </div>
        ))
      )}

      <br></br>
      <br></br>
      <br></br>
    </Container>
  );
};

HomePage.defaultProps = {};

export default HomePage;
