import {
  Box,
  chakra,
  Container,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
  Image,
} from "@chakra-ui/react";
import { FaInstagram, FaTwitter, FaGithub, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";
import logo from "../../static/logos/final_logo.svg";

const Logo = (props) => {
  return (
    <Box size="sm">
      <Link to="/">
        <Image
          src={logo}
          boxSize="50px"
          objectFit="cover"
          rounded="full"
          alt="shivthedev"
        />
      </Link>
    </Box>
  );
};

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      target="_blank"
      rel="noopener noreferrer"
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function SmallCentered() {
  return (
    <>
      <div style={{ marginTop: "80vh" }}>
        <Box
          bg={useColorModeValue("gray.50", "gray.900")}
          color={useColorModeValue("gray.700", "gray.200")}
        >
          <Container
            as={Stack}
            maxW={"6xl"}
            py={4}
            spacing={4}
            justify={"center"}
            align={"center"}
          >
            <Logo />
            <Stack direction={"row"} spacing={6}>
              <Link to="/">Home</Link>
              <Link to="/blog">Blog</Link>
              <Link to="/profile">My Profile</Link>
              <Link to="/contact">Contact</Link>
            </Stack>
          </Container>

          <Box
            borderTopWidth={1}
            borderStyle={"solid"}
            borderColor={useColorModeValue("gray.200", "gray.700")}
          >
            <Container
              as={Stack}
              maxW={"6xl"}
              py={4}
              direction={{ base: "column", md: "row" }}
              spacing={4}
              justify={{ base: "center", md: "space-between" }}
              align={{ base: "center", md: "center" }}
            >
              <Text>© 2022 ShivtheDev.Tech. All rights reserved</Text>
              <Stack direction={"row"} spacing={6}>
                <SocialButton
                  label={"Twitter"}
                  href={"https://twitter.com/citysiva180"}
                >
                  <FaTwitter />
                </SocialButton>
                <SocialButton
                  label={"GitHub"}
                  href={"https://github.com/citysiva180"}
                >
                  <FaGithub />
                </SocialButton>
                <SocialButton
                  label={"LinkedIn"}
                  href={"https://github.com/citysiva180"}
                >
                  <FaLinkedin />
                </SocialButton>
                <SocialButton
                  label={"Instagram"}
                  href={"https://instagram.com/siv_the_photographer"}
                >
                  <FaInstagram />
                </SocialButton>
              </Stack>
            </Container>
          </Box>
        </Box>
      </div>
    </>
  );
}
