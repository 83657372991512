import React from "react";
import {
  chakra,
  Box,
  Image,
  Flex,
  useColorModeValue,
  Link,
  Heading,
  Center,
} from "@chakra-ui/react";
import strange from "./images/SomethingStrange.png";
import appImage from "./images/FeedbackApp.png";
import DoneWithIt from "./images/DonewithIt.png";

const Ma = () => {
  return (
    <>
      <Center>
        <Heading color={useColorModeValue("teal.600", "teal.300")}>
          My Projects
        </Heading>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </Center>
      <Flex
        bg={useColorModeValue("#F9FAFB", "gray.600")}
        p={50}
        w="full"
        alignItems="center"
        justifyContent="center"
      >
        {/* <Heading>My Projects</Heading> */}

        <Box
          w="xs"
          bg={useColorModeValue("white", "gray.800")}
          shadow="lg"
          rounded="lg"
          overflow="hidden"
          mx="auto"
        >
          <Image w="full" h={56} fit="cover" src={appImage} alt="avatar" />

          <Box py={5} textAlign="center">
            <Link
              display="block"
              fontSize="2xl"
              color={useColorModeValue("gray.800", "white")}
              fontWeight="bold"
              href="https://github.com/citysiva180/feedback-app"
            >
              Feedback App
            </Link>
            <chakra.span
              fontSize="sm"
              color={useColorModeValue("gray.700", "gray.200")}
            >
              A Simple Form for Feedback
            </chakra.span>
          </Box>
        </Box>

        <Box
          w="xs"
          bg={useColorModeValue("white", "gray.800")}
          shadow="lg"
          rounded="lg"
          overflow="hidden"
          mx="auto"
        >
          <Image w="full" h={56} fit="cover" src={strange} alt="avatar" />

          <Box py={5} textAlign="center">
            <Link
              display="block"
              fontSize="2xl"
              color={useColorModeValue("gray.800", "white")}
              fontWeight="bold"
              href="https://github.com/citysiva180/react-auth"
            >
              Something Strange
            </Link>
            <chakra.span
              fontSize="sm"
              color={useColorModeValue("gray.700", "gray.200")}
            >
              A Fandom website
            </chakra.span>
          </Box>
        </Box>

        <Box
          w="xs"
          bg={useColorModeValue("white", "gray.800")}
          shadow="lg"
          rounded="lg"
          overflow="hidden"
          mx="auto"
        >
          <Image w="full" h={56} fit="cover" src={DoneWithIt} alt="avatar" />

          <Box py={5} textAlign="center">
            <Link
              display="block"
              fontSize="2xl"
              color={useColorModeValue("gray.800", "white")}
              fontWeight="bold"
              href="https://github.com/citysiva180/DoneWithIt"
            >
              DoneWithIt
            </Link>
            <chakra.span
              fontSize="sm"
              color={useColorModeValue("gray.700", "gray.200")}
            >
              React Native App
            </chakra.span>
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default Ma;
