import React from "react";
import {
  Container,
  Heading,
  Center,
  Text,
  useColorModeValue,
  Link,
  Box,
} from "@chakra-ui/react";

import new_data from "./data_file.json";
import TableData from "../../utils/Table/Table_Utility";

const CheatSheet = () => {
  return (
    <Container maxW="container.xl">
      <br></br>
      <br></br>
      <br></br>
      <Heading>
        <Center color={useColorModeValue("teal.600", "teal.300")}>
          Django ORM Cheat Sheet
        </Center>
      </Heading>
      <br></br>
      <br></br>
      <Text fontSize="xl">
        Welcome to the dedicated Comprehensive Cheat sheet on Django ORM.
        Learning ORM is great but there are so many concepts that you would need
        to remember while building your app. This Cheat Sheet will help you to
        understand the ORM concepts in much better way. Using this cheat sheet
        you could now build amazing API views for your Django project. I hope
        this helps those new developers struggling to learn ORM. I credit my
        long time online tutor Mosh Hamedani{" "}
        <Link color="teal.500" href="https://www.codewithmosh.com">
          www.codewithmosh.com
        </Link>{" "}
        for his comprehensive Django Course where I learnt a lot.
      </Text>
      <br></br>
      <br></br>

      <Box>
        {Object.entries(new_data[0]).map((data, i) => (
          <>
            <div key={i}>
              <TableData table_data={data}></TableData>
            </div>
            <br></br>
            <br></br>
          </>
        ))}
      </Box>
    </Container>
  );
};

export default CheatSheet;
