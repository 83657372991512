import React from "react";
import {
  chakra,
  Box,
  SimpleGrid,
  Flex,
  useColorModeValue,
  Icon,
} from "@chakra-ui/react";

import { FaLaptopCode, FaDraftingCompass, FaRobot } from "react-icons/fa";

export default function S3c() {
  const Feature = (props) => {
    return (
      <Box>
        <Icon
          boxSize={12}
          color={useColorModeValue("brand.700")}
          mb={4}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          {props.icon}
        </Icon>
        <chakra.h3
          mb={3}
          fontSize="lg"
          lineHeight="shorter"
          fontWeight="bold"
          color={useColorModeValue("gray.900")}
        >
          {props.title}
        </chakra.h3>
        <chakra.p
          lineHeight="tall"
          color={useColorModeValue("gray.600", "gray.400")}
        >
          {props.children}
        </chakra.p>
      </Box>
    );
  };

  return (
    <Flex
      bg={useColorModeValue("#F9FAFB", "teal.400")}
      //   p={2}
      w="auto"
      justifyContent="center"
      alignItems="center"
    >
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3 }}
        spacing={20}
        px={{ base: 4, lg: 16, xl: 24 }}
        py={20}
        mx="auto"
        bg={useColorModeValue("white", "gray.800")}
        // shadow="xl"
      >
        <Feature
          title="Fullstack Developer"
          icon={<FaLaptopCode></FaLaptopCode>}
        >
          I love working with JavaScript and Python to build amazing web apps. I
          also love no-SQL DB like mongo and love caching relational data via
          Redis
        </Feature>

        <Feature
          title="App Designer"
          icon={<FaDraftingCompass></FaDraftingCompass>}
        >
          I love designing my apps by myself! I have completed certification in
          app design using Autodesk sketch and love designing my apps. I have as
          well taken digital arts course learning about colour theory and
          digital design
        </Feature>

        <Feature title="Budding Cloud / AI Engineer" icon={<FaRobot></FaRobot>}>
          Apps now have become smart and they have been in the cloud for high
          availability. I had been active in this domain as well. I am actively
          progressing in Amazon Web Services and Google Cloud Platform as well
          love to do ML using python
        </Feature>
      </SimpleGrid>
    </Flex>
  );
}
