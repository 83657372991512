import React from "react";
import { Stack, Text, Button, Image } from "@chakra-ui/react";

import photography from "./images/photography.jpg";
import digitalArts from "./images/digitalArts.jpg";
export default function SimpleCookiePreference() {
  return (
    <>
      <Stack p="4" m="4" borderRadius="30">
        <Stack direction="row" alignItems="center">
          <Text fontSize="30px" fontWeight="bold">
            Photography
          </Text>
        </Stack>

        <br></br>
        <Stack
          direction={{ base: "column", md: "row" }}
          justifyContent="space-between"
        >
          <Image src={photography} w={90} shadow="lg" h={90}></Image>

          <Text textAlign={"left"} maxW={"4xl"} p={3}>
            I had always loved capturing images and loved the physics involved.
            I have trained myself hard in photography and have always been happy
            to take photos. I have experimented with my skills as well received
            a certification. Please do visit my Instagram handle
            @siv_the_photographer to enjoy my photography
          </Text>
          <Stack direction={{ base: "column", md: "row" }}>
            <Button colorScheme="teal.300">Visit my Site</Button>
          </Stack>
        </Stack>
      </Stack>
      <Stack p="4" m="4" borderRadius="30">
        <Stack direction="row" alignItems="center">
          <Text fontSize="30px" fontWeight="bold">
            Digital Arts
          </Text>
        </Stack>

        <br></br>
        <Stack
          direction={{ base: "column", md: "row" }}
          justifyContent="space-between"
        >
          <Image src={digitalArts} w={90} h={90}></Image>

          <Text textAlign={"left"} maxW={"4xl"} p={3}>
            Digital Arts had been my go-to zone mode option. I love designing
            and working with different colours. I have been an active designer
            and kept a watch out for the arts community. Do check out my Behance
            Profile the enjoy the art
          </Text>
          <Stack direction={{ base: "column", md: "row" }}>
            <Button colorScheme="teal.300">Visit my Site</Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
