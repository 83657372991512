import React from "react";
import {
  chakra,
  Box,
  SimpleGrid,
  Flex,
  useColorModeValue,
  Icon,
} from "@chakra-ui/react";

import {
  FaPython,
  FaAws,
  FaGitAlt,
  FaLinux,
  FaReact,
  FaSketch,
} from "react-icons/fa";
import {
  SiRedis,
  SiMongodb,
  SiDjango,
  SiServerless,
  SiJavascript,
  SiMysql,
} from "react-icons/si";

export default function Fgl() {
  const Feature = (props) => {
    return (
      <Box>
        <Flex
          alignItems="center"
          justifyContent="center"
          w={8}
          h={8}
          mb={4}
          rounded="full"
          color={useColorModeValue(`${props.color}.600`, `${props.color}.100`)}
          bg={useColorModeValue(`${props.color}.100`, `${props.color}.600`)}
        >
          <Icon
            boxSize={5}
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            {props.icon}
          </Icon>
        </Flex>
        <chakra.h3
          mb={2}
          fontWeight="semibold"
          lineHeight="shorter"
          color={useColorModeValue("gray.900")}
        >
          {props.title}
        </chakra.h3>
        <chakra.p
          fontSize="sm"
          color={useColorModeValue("gray.500", "gray.400")}
        >
          {props.children}
        </chakra.p>
      </Box>
    );
  };
  return (
    <Flex
      bg={useColorModeValue("#F9FAFB", "gray.600")}
      //   p={20}
      w="auto"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        px={8}
        py={20}
        mx="auto"
        bg={useColorModeValue("white", "gray.800")}
        // shadow="xl"
      >
        <Box textAlign={{ lg: "center" }}>
          <chakra.p
            mt={2}
            fontSize={{ base: "3xl", sm: "4xl" }}
            lineHeight="8"
            fontWeight="extrabold"
            letterSpacing="tight"
            color={useColorModeValue("teal.600", "teal.300")}
          >
            Skill Profile
          </chakra.p>
          <chakra.p
            mt={4}
            maxW="2xl"
            fontSize="xl"
            mx={{ lg: "auto" }}
            color={useColorModeValue("gray.500", "gray.400")}
          >
            Please find the list of skill / tech I have worked with
          </chakra.p>
        </Box>
        <SimpleGrid
          columns={{ base: 1, sm: 2, md: 3, lg: 4 }}
          spacingX={{ base: 16, lg: 24 }}
          spacingY={20}
          mt={6}
        >
          <Feature color="blue" title="Python" icon={<FaPython></FaPython>}>
            Knows Advanced python with code modulation, file management along
            with exposure to web frameworks
          </Feature>

          <Feature
            color="yellow"
            title="JavaScript"
            icon={<SiJavascript></SiJavascript>}
          >
            Knows JavaScript ES7 Syntaxes including arrow functions, mixins and
            spread operators
          </Feature>

          <Feature
            color="orange"
            title="Amazon Web Services"
            icon={<FaAws></FaAws>}
          >
            Knows Lambda, Triggers, API Gateway, S3 and CloudFront CDN
          </Feature>

          <Feature color="red" title="Redis" icon={<SiRedis></SiRedis>}>
            Knows to read and write keys and HSETS in Redis with python as well
            as knows basic Redis commands
          </Feature>
          <Feature color="purple" title="My SQL" icon={<SiMysql></SiMysql>}>
            Knows relational database modelling with Transactions, Concurreny,
            Triggers and Events
          </Feature>
          <Feature color="blue" title="React" icon={<FaReact></FaReact>}>
            Knows building functional and Reusable components with React, knows
            to apply redux, passport js for authentication and build forms with
            react Formik
          </Feature>
          <Feature
            color="yellow"
            title="Auto Desk Sketch"
            icon={<FaSketch></FaSketch>}
          >
            Knows to design, prototype mobile and web apps as well export
            elements for developments
          </Feature>

          <Feature color="green" title="Django" icon={<SiDjango></SiDjango>}>
            Knows MVT architecture to build Django Apps. Knows to build Django
            Apps with Django ORM, Knows Django Authentication and Authorization.
          </Feature>

          <Feature
            color="green"
            title="Mongo DB"
            icon={<SiMongodb></SiMongodb>}
          >
            Knows to build clusters and work with Mongo Shell. Knows to use
            No-SQL DB modelling as well.
          </Feature>

          <Feature
            color="pink"
            title="Serverless"
            icon={<SiServerless></SiServerless>}
          >
            Knows to deploy AWS Lambdas with serverless for quicker and
            efficient deployment of Lambdas
          </Feature>

          <Feature
            color="green"
            title="Linux Commands"
            icon={<FaLinux></FaLinux>}
          >
            Knows Linux command, bash scripting and file management commands
            such as rm, brace expansion and wildcards for searching through
            Linux
          </Feature>

          <Feature color="red" title="Git" icon={<FaGitAlt></FaGitAlt>}>
            Completed certification for git, knows to run git command, manage branches and users.
          </Feature>
        </SimpleGrid>
      </Box>
    </Flex>
  );
}
