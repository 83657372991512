import React from "react";
import {
  Heading,
  Center,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

const TableData = ({ table_data }) => {
  const cell_color = useColorModeValue("teal.600", "teal.900");
  return (
    <>
      <Center>
        <Heading as="h4" size="md">
          {table_data[0]}
        </Heading>
      </Center>
      <br></br>
      <br></br>

      <TableContainer overflowWrap="normal">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>S.NO</Th>
              <Th>FUNCTIONALITY</Th>
              <Th>ORM SYNTAX</Th>
              <Th>DESCRIPTION</Th>
            </Tr>
          </Thead>
          <Tbody>
            {table_data[1].map((data, i) => (
              <Tr key={i}>
                <Td>{i + 1}</Td>
                <Td
                  minWidth={250}
                  maxWidth={250}
                  style={{
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                  }}
                >
                  <Text>{data["DESCRIPTION"]}</Text>
                </Td>

                <Td
                  fontSize="sm"
                  backgroundColor={cell_color}
                  minWidth={600}
                  maxWidth={600}
                  color={"white"}
                >
                  <strong>
                    <pre
                      style={{
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                      }}
                    >
                      <code>{data["ORM SYNTAX"]}</code>
                    </pre>
                  </strong>
                </Td>
                <Td
                  style={{
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                  }}
                >
                  <Text>{data["FUNCTIONALITY"]}</Text>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <br></br>
      <br></br>
    </>
  );
};

export default TableData;
