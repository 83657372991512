import React from "react";
import { useColorModeValue, Center, Heading } from "@chakra-ui/react";
import HobbyCard from "./HobbyCard";
const MyHobbies = () => {
  return (
    <>
      <br></br>
      <br></br>
      <Center>
        <Heading color={useColorModeValue("teal.600", "teal.300")}>
          My Hobbies
        </Heading>
      </Center>
      <HobbyCard></HobbyCard>
    </>
  );
};

export default MyHobbies;
