import React from "react";
import { Container } from "@chakra-ui/react";
import ContactFormWithSocialButtons from "./ContactForm";
const ContactPage = () => {
  return (
    <Container maxW="container.xl">
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <ContactFormWithSocialButtons></ContactFormWithSocialButtons>
    </Container>
  );
};

export default ContactPage;
